import React from "react";
import Iframe from "react-iframe";
import { useSelector} from "react-redux";
import Axios from "axios";

// reactstrap components
import {
  Alert,
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Label,
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";;


// core components

function Contacto() {

  const [nameFocus, setNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [nombres, setNombres] = React.useState("");
  const [correo, setCorreo] = React.useState("");
  const [mensaje, setMensaje] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const [errorEmail, setErrorEmail] = React.useState({mostrar:false, tipo:'danger', message:''});

  const notaria = useSelector((state) => state.notaria.notaria);

  const enviarCorreo = async (e) => {
    e.preventDefault()
    if(checked && nombres !==''&& correo !=='' && mensaje !==''){
      try{
          let config = {
            method: 'POST',
            mode: 'cors',
            headers: {"Access-Control-Allow-Origin": true,Authorization: "Bearer "+ process.env.REACT_APP_TOKEN_API},
            }
            let data = {
              'remitente_name': nombres, 
              'remitente_email': correo, 
              'descripcion':mensaje,
              'destinatario': notaria.email_contactenos            
            }            
          const resultado = await Axios.post(process.env.REACT_APP_UrlApi+'MailContact',data,config)
          
          if(resultado.status === 200){
            setNombres('')
            setCorreo('')
            setMensaje('')
            setErrorEmail({mostrar:true, tipo:'success', message:'Correo Enviado, pronto le daremos una respuesta'})       
          }else{
            setErrorEmail({mostrar:true, tipo:'danger', message:'Sucedió un error, intentarlo mas tarde'})
          }
      }
      catch(eroror){
        setErrorEmail({mostrar:true, tipo:'danger', message:'Sucedió un error, intentarlo mas tarde'})
      }
    }else{
      setErrorEmail({mostrar:true, tipo:'danger', message:'Campos vacíos'})
      setTimeout(() => {
        setErrorEmail({mostrar:false, tipo:'danger', message:''})
      }, 3000)
    }
 
  }  

  return (
    <>
      <div id="contacto" style={{ backgroundColor: "White" }}>
      <Container>
          <Row className="justify-content-center">
            <Col md="10">
              <h1 className="text-center title">
                <b style={{color : "#0a006c"}}>Información </b>de contacto<br></br>
              </h1>
              <h4 className="text-center description">
                Necesita más información? Visite nuestras oficinas o comuníquese con nuestras
                líneas de atención al cliente para recibir información adicional.
              </h4>
            </Col>
          </Row>
          <Row className="justify-content-center" style={{marginTop: "20px"}}>
            <Col lg="6">
              <Card style={{borderRadius: "15px", padding: "30px 30px 30px 30px", backgroundColor: "#fff0"}}>
                <CardBody style={{ border: "1px solid #9A9A9A", borderRadius: "15px", backgroundColor: "#fff"}}>
                  <h3 className="title text-center" style={{marginTop: "-25px"}}>Contáctenos por <br></br>Correo electrónico</h3>
                  <Form id="contact-form" method="post" role="form">
                    <label>Nombre</label>
                    <InputGroup
                      className={nameFocus ? "input-group-focus" : ""}
                    >
                        <InputGroupText>
                          <i className="now-ui-icons users_circle-08"></i>
                        </InputGroupText>
                      <Input
                        placeholder="Nombre completo"
                        type="text"
                        onFocus={() => setNameFocus(true)}
                        onBlur={() => setNameFocus(false)}
                        onChange={(e) =>setNombres(e.target.value)}
                      ></Input>
                    </InputGroup>
                    <label>Correo electrónico</label>
                    <InputGroup
                      className={emailFocus ? "input-group-focus" : ""}
                    >
                        <InputGroupText>
                          <i className="now-ui-icons ui-1_email-85"></i>
                        </InputGroupText>
                      <Input
                        placeholder="example@email.com"
                        type="email"
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                        onChange={(e) =>setCorreo(e.target.value)}
                      ></Input>
                    </InputGroup>
                    <FormGroup>
                      <label> Mensaje</label>
                      <Input
                        id="message"
                        name="message"
                        rows="6"
                        type="textarea"
                        onChange={(e) =>setMensaje(e.target.value)}
                      ></Input>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input 
                          type="checkbox"
                          onChange={(e) =>setChecked(true)}
                        ></Input>
                        <span className="form-check-sign"></span>
                          No soy un robot 
                      </Label>
                    </FormGroup>
                    <br></br>
                    <div className="submit text-center">
                      <Button
                        color="azul"
                        className="btn-raised btn-round"
                        defaultValue="Enviar"
                        //type="submit"
                        onClick={(e) => enviarCorreo(e)}
                      >
                        <b style={{fontSize: "16px"}}>Enviar Mensaje</b>
                      </Button>
                    </div>
                  </Form>
                  {errorEmail.mostrar &&
                    <Row className="justify-content-center" id="error">
                      <Col ms="6">
                        <Alert color={errorEmail.tipo}>
                          <b style={{fontSize: "16px"}}>{errorEmail.message}</b>
                        </Alert>
                      </Col>
                    </Row>
                  }
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card style={{borderRadius: "15px"}}>
                <CardBody>
                  <h3 className="title text-center" style={{marginTop: "-25px"}}>Contáctenos por <br></br>Whatsapp</h3>
                  <Row>
                    <Col md="6">
                      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"/>
                      <a href="https://wa.me/573174037890"  className="wp">
                        <p><i className="fa fa-whatsapp my-float"></i> Escrituración, Sucesiones y Divorcios </p>
                      </a>
                    </Col>
                    <Col md="6">
                      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"/>
                      <a href="https://wa.me/573173533068"  className="wp">
                        <p><i className="fa fa-whatsapp my-float"></i> Constructoras </p>
                      </a>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"/>
                    <a href="https://wa.me/573152336135"  className="wp">
                      <p><i className="fa fa-whatsapp my-float"></i> Constructoras y Matrimonios </p>
                    </a>
                    </Col>
                    <Col md="4">
                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"/>
                    <a href="https://wa.me/573158996822"  className="wp">
                      <p><i className="fa fa-whatsapp my-float"></i> Escrituración</p>
                    </a>
                    </Col>
                    <Col md="4">
                    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"/>
                    <a href="https://wa.me/573185009110"  className="wp">
                      <p><i className="fa fa-whatsapp my-float"></i> Registro civil - Autenticaciones</p>
                    </a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card style={{borderRadius: "15px"}}>
                <CardBody>
                  <h3 className="title text-center" style={{marginTop: "-25px"}}>Teléfonos</h3>
                  <Row>
                    <p><i className="now-ui-icons tech_mobile" style={{color: "#bd0b0e"}}></i> +57 (602) 347 27 57</p>
                    <p><i className="now-ui-icons tech_mobile" style={{color: "#bd0b0e"}}></i> +57 (602) 392 11 83</p>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className= "justify-content-center">
            <Col lg="6">
              <Card style={{borderRadius: "15px", padding: "30px 30px 30px 30px", backgroundColor: "#fff0"}}>
                <CardBody style={{ border: "1px solid #9A9A9A", borderRadius: "15px", backgroundColor: "#fff"}}>
                  <h4 className="title text-center" style={{marginTop: "-25px"}}>Correo institucional:</h4>
                  <h4 className="text-center" style={{marginTop: "-25px"}}>veintiuncali@supernotariado.gov.co</h4>
                  <h4 className="title text-center" style={{marginTop: "-4px"}}>Notificaciones judiciales:</h4>
                  <h4 className="text-center" style={{marginTop: "-25px"}}> notjudicialesnotaria21cali@gmail.com</h4>                                   
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card style={{borderRadius: "15px", padding: "30px 30px 30px 30px", backgroundColor: "#fff0"}}>
                <CardBody style={{ border: "1px solid #9A9A9A", borderRadius: "15px", backgroundColor: "#fff"}}>
                  <h4 className="title text-center" style={{marginTop: "-25px"}}>Correo físico:</h4>
                  <h4 className="text-center" style={{marginTop: "-25px"}}>Carrera 100 # 11-60 Locales 234A, 234B, 234C, 235, 235A, 235B</h4>
                  <h4 className="text-center" style={{marginTop: "-25px"}}>Cali -Valle del Cauca</h4>
                  <h4 className="text-center">Código Postal: 720036</h4>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className= "justify-content-center">
            <Col lg="6">
              <Card style={{borderRadius: "15px", padding: "30px 30px 30px 30px", backgroundColor: "#fff0"}}>
                <CardBody style={{ border: "1px solid #9A9A9A", borderRadius: "15px", backgroundColor: "#fff"}}>
                  <h4 className="title text-center" style={{marginTop: "-25px"}}>Rutas:
                  </h4>
                  <center>
                    <a href={"https://www.waze.com/livemap/directions/colombia/valle-del-cauca/cali/notaria-21-cali?place=ChIJu-hmrnuhMI4R-rCnFR-MsPY&utm_expid=.K6QI8s_pTz6FfRdYRPpI3A.0&utm_referrer="} style={{borderBottom: "none"}}>  
                        <img src={require("assets/img/Notaria/waze.png")} alt="Logo de la aplicación de Waze" width="13%"/>
                    </a>{"    "}
                    <a  href={"https://www.google.com/maps/dir/?api=1&destination=Notaria+21+de+Santiago+de+Cali,+Cra.+100+%23%2311-60+Local+234B,+Cali,+Valle+del+Cauca"} style={{borderBottom: "none"}}>
                        <img src={require("assets/img/Notaria/google.png")} alt="Logo de la aplicación de Google" width="13%"/>
                    </a>
                   </center> 
                </CardBody>
              </Card>     
            </Col>
            <Col lg="6">
              <Card style={{borderRadius: "15px", padding: "30px 30px 30px 30px", backgroundColor: "#fff0"}}>
                <CardBody style={{ border: "1px solid #9A9A9A", borderRadius: "15px", backgroundColor: "#fff"}}>
                  <h4 className="title text-center" style={{marginTop: "-25px"}}>Atención al público:</h4>
                    <center>
                      <a href="/peticiones" className="btn btn-azul" aria-disabled="true" role="button">
                          <b style={{fontSize: "15px"}}> PQRS</b>
                      </a>
                    </center>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br/>
          <Row className= "justify-content-center">
            <Col lg="12">
              <h2 className="text-center title" style={{marginTop: "-20px"}}>
                  <b style={{color : "#0a006c"}}>Ubicación</b><br></br>
              </h2>
              <Card style={{borderRadius: "15px"}}>
                <CardBody>
                  <Iframe url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15931.636513335576!2d-76.5399403!3d3.3723869!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf6b08c1f15a7b0fa!2sNotaria%2021%20de%20Santiago%20de%20Cali!5e0!3m2!1ses-419!2sco!4v1596807187268!5m2!1ses-419!2sco" 
                        width = "100%"
                        frameBorder = "0" 
                        height="40%" 
                        className="text-center"
                        frameborder="0"
                        allowfullscreen="" 
                        aria-hidden="false" 
                        tabindex="0">
                  </Iframe>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <b>Entidades, agremiaciones y asociaciones</b>
              <Row>
                <Col md="1" sm="1">
                  <a href="https://www.procuraduria.gov.co/portal/" target="_blank" rel="noopener noreferrer" style={{borderBottom: "none"}}>
                    <img
                      alt="Logo de Procuraduria General de la Nacion"
                      src={require("assets/img/Notaria/procuraduria.jpg")}
                    ></img>
                  </a>
                </Col>
                <Col ms="2">
                <a href="http://www.minjusticia.gov.co/" target="_blank" rel="noopener noreferrer" style={{borderBottom: "none"}}>
                  <img
                      alt="Logo de Ministero de Justicia y Derecho de Colombia"
                      src={require("assets/img/Notaria/justicia.png")}
                  ></img>
                </a>
                </Col>
                <Col ms="2">
                <a href="https://www.supernotariado.gov.co/" target="_blank" rel="noopener noreferrer" style={{borderBottom: "none"}}>
                  <img
                      alt="Logo de SNR y Registro"
                      src={require("assets/img/Notaria/LOGO_SNR.png")}
                  ></img>
                  </a>
                </Col>
                <Col ms="2" className="text-center">
                  <center>
                  <a href="https://www.suin-juriscol.gov.co/" target="_blank" rel="noopener noreferrer" style={{borderBottom: "none"}}>
                    <img
                        alt="Logo de SUIN Juriscol"
                        width="120px"
                        src={require("assets/img/Notaria/suin.png")}
                    ></img>
                    </a>
                  </center>
                </Col>
                <Col ms="2">
                <a href="https://www.registraduria.gov.co/" target="_blank" rel="noopener noreferrer" style={{borderBottom: "none"}}>
                  <img
                      alt="Logo de la Registraduaría nacional del estado civil"
                      width="100px"
                      src={require("assets/img/Notaria/registraduria.png")}
                  ></img>
                  </a>
                </Col>
                <Col ms="2">
                  <center></center>
                <a href="https://www.vur.gov.co/" target="_blank" rel="noopener noreferrer" style={{borderBottom: "none"}}>
                  <img
                      alt="Logo de la Ventanilla única de registro"
                      width="100px"
                      src={require("assets/img/Notaria/vur.png")}
                  ></img>
                  </a>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <b>Directorio de agremiaciones, asociaciones y otros grupos de interés</b>
              <Row>
                <Col md="2" sm="2">
                  <center>
                  <a href="https://www.ucnc.com.co/sitio/" target="_blank" rel="noopener noreferrer" style={{borderBottom: "none"}}>
                    <img
                        alt="Logo de la Unión Colegiada del Notariado Colombiano"
                        width="120px"
                        src={require("assets/img/Notaria/notario.jpg")}
                    ></img>
                    </a>
                  </center>
                </Col>
                <Col md="2" sm="2">
                  <a href="https://www.gobiernoenlinea.gov.co/" target="_blank" rel="noopener noreferrer" style={{borderBottom: "none"}}>
                    <img
                          alt="Logo del Gobierno de Colombia" 
                          
                          src={require("assets/img/Notaria/gobierno.png")}
                    ></img>
                  </a>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <br></br>
        </Container>
      </div>
    </>
  );
}

export default Contacto;
