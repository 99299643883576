import React from "react";

// reactstrap components
import { Card,
          CardBody,
          Carousel, 
          CarouselItem,
          Container,
          Row,
          Col,} from "reactstrap";

// core components
const items = [
  {
    id: 2,
    caption: "Zona de Pagos",
    boton: "Zona de Pagos",
    text: "En este módulo podra realizar pagos en linea de servicios notariales.",
    url: "/zonaPagos"
  },
  {
    id: 3,
    caption: "Cotizador online",
    boton: "Cotización",
    text: "En este módulo podrá realizar las cotizaciones previas a sus escrituras de: Compraventa, Compraventa e hipoteca con particulares y/o entidades financieras, Hipotecas, cancelaciones de hipoteca, matrimonios y sucesiones, entre otras.",
    url: "/cotizar"
  },
  {
    id: 1,
    caption: "Estimados Clientes",
    boton: "Lineas Telefonicas",
    text: "Cambiamos nuestras líneas telefónicas, ahora puedes comunicarte con nosotros marcando los siguientes números: 602 3472757 o 602 3921183."
  }  
];

function Linea() {

  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  // const goToIndex = newIndex => {
  //   if (animating) return;
  //   setActiveIndex(newIndex);
  // };

  return (
    <>
      <div
        className="contactus-1 section-image"
        id = "linea"
        style={{
          backgroundSize: "100% 100%",
          backgroundImage: "url(" + require("assets/img/Notaria/linea.jpg") + ")",
        }}
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="5" md="7">
              <Card style={{borderRadius : "15px", border: "1px solid #fff", backgroundColor: "#fff0"}}>
                <CardBody className="text-center">
                  <h1 className="title" style={{paddingTop: "15px"}}>
                      Noticias
                  </h1>
                  {/* <h3 className="description" style={{color: "White"}}>
                    <b>En esta sección encontrará aplicativos online 
                        para agilizar sus procesos notariales</b>
                  </h3> */}
                </CardBody>
              </Card>
            </Col>
            <Col lg="7" md="12">
              <Carousel activeIndex={activeIndex} next={next} previous={previous} style={{borderRadius: "15px"}}>
                {items.map(item => {
                  return (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={item.id}
                    >
                      <Card className="text-center" 
                            style={{width:"100%", 
                                    margin: "0",
                                    borderRadius: "15px",
                                    border: "1px solid #fff", 
                                    backgroundColor: "#fff0"}}>
                        <CardBody>
                          <h1 className="title" style={{marginTop: "-10px"}}>{item.caption}</h1>
                          <div className="text-center">
                            <p className="card-description" style={{color: "#fff"}}><b>{item.text}</b></p>
                            <a href={item.url} className="btn btn-azul btn-lg" aria-disabled="true" role="button">
                                <b style={{fontSize: "16px"}}>{item.boton}</b>
                            </a>
                            <br></br>
                          </div>
                        </CardBody>
                      </Card>
                    </CarouselItem>
                  );
                })}
                <a
                  className="carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                  style={{width: "10%"}}
                >
                  <b><i className="now-ui-icons arrows-1_minimal-left"></i></b>
                </a>
                <a
                  className="carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                  style={{width: "10%"}}
                >
                  <b><i className="now-ui-icons arrows-1_minimal-right"></i></b>
                </a>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Linea;
